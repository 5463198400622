"use strict";

import $ from "jquery";
import { equalHeights } from "../util/universal";
import Flickity from "flickity";


export default class GlobalSliderController {

    /**
     * Slider collection.
     * @var {Object<string,Flickity>} sliders
     */
    sliders = {};

    /**
     * Class constructor.
     */
    constructor() {
        $( this.onReady.bind( this ) );
    }

    /**
     * Called when ready.
     */
    onReady() {
        if ( ! $( ".global-slider").length ) {
            return;
        }

        document.querySelectorAll( ".global-slider" ).forEach( this.initialiseSlider.bind( this ) );

        console.info( "Initialised GlobalSliderController" );
    }

    /**
     * Initialise a Flickity slider.
     * @param {HTMLElement} element 
     */
    initialiseSlider( element ) {
        let flkty_slider = null;
        const equalHeightClass = element.querySelectorAll( ".card-item" );
        const navWrapper = element.parentElement.parentElement;
        const btnPrev = navWrapper.querySelector( ".slider-prev" );
        const btnNext = navWrapper.querySelector( ".slider-next" );

        equalHeights(equalHeightClass);

        flkty_slider = new Flickity( element, {
            cellAlign: "left",
            wrapAround: false,
            contain: true,
            pageDots: true,
            prevNextButtons: false,
            watchCSS: true,
            on: {
                ready: function () {
                    equalHeights(equalHeightClass);
                },
                resize: function () {
                    equalHeights(equalHeightClass);
                },
            },
        });

        $(window).on({
            "is-toggled": $.debounce(150, () => {
                flkty_slider.resize();
            }),
        });

        if(btnPrev && btnNext) {
            if(btnPrev) {
                btnPrev.addEventListener("click", () => {
                    flkty_slider.previous();
                });
            }

            if(btnNext) {
                btnNext.addEventListener("click", () => {
                    flkty_slider.next();
                });
            }

            flkty_slider.on("select", () => {
                if (!flkty_slider.slides[flkty_slider.selectedIndex - 1]) {
                    btnPrev.setAttribute("disabled", "disabled");
                    btnNext.removeAttribute("disabled");
                } else if (
                    !flkty_slider.slides[flkty_slider.selectedIndex + 1]
                ) {
                    btnNext.setAttribute("disabled", "disabled");
                    btnPrev.removeAttribute("disabled");
                } else {
                    btnNext.removeAttribute("disabled");
                    btnPrev.removeAttribute("disabled");
                }
            });
        }

        flkty_slider.resize();

        window.addEventListener("resize", function () {
            equalHeightClass.forEach((sliderItem) => {
                sliderItem.style.height = "auto";
            });

            flkty_slider.resize();
        });
    }
}